import React from 'react';


function DigitalMarketing() {
  return(
      <div id='dm_container'>

        <div id='dm_header'>
            <h1 id='dm_header_h1'>Digital Marketing</h1>
            <h2 id='dm_header_h2'>
            Build your brand, streamline your online presence and increase profitability with best digital marketing services.
            </h2>
        </div>

        <div id='dm_content1'>
            <h1 id='dm_content1_h1'>WE USE OUR DIGITAL EXPERTISE TO EXCEED YOUR EXPECTATIONS</h1>
            <p id='dm_content1_p'>
            Most clients who walk in through our front door already know what they want to achieve with digital marketing. 
            Our mission in this is twofold- first to identify and marshal the right digital instruments and second, 
            train the spotlight on the services that will get them the desired results. Whether you are setting your eyes 
            on a new market or looking for ways to establish yourself as a thought leader in your industry, there's digital 
            marketing strategy for everything and anything you envision attaining.
            </p>
        </div>

        <div id='dm_content2'>
            <h1 id='dm_content2_h1'>The Digital Marketing Solution</h1>
            <p id='dm_content2_p'>
            Aside from the obvious fact that we are a digital marketing agency with over 5 years of experience, we believe 
            it's our approach to digital marketing that sets us apart. Our methodology is refreshingly uncomplicated - 
            we first sit down with you to get an idea of your business objectives and then develop an integrated plan to turn 
            it into a tangible reality. We handle all your digital marketing activities including the following:
            </p>
            <div id='dm_p_list'>
                <p>Search engine optimization campaigns</p>
                <p>Content Marketing</p>
                <p>Paid Search</p>
                <p>Social Media Marketing</p>
                <p>Local search marketing</p>
                <p>Web analytics</p>
                <p>Direct email marketing</p>
            </div>
        </div>

        <div id='dm_content3'>
            <h1 id='dm_content3_h1'>Problem Statement</h1>
            <p id='dm_content3_p'>
            Most clients who walk in through our front door already know what they want to achieve with digital marketing. 
            Our mission in this is twofold- first to identify and marshal the right digital instruments and second, 
            train the spotlight on the services that will get them the desired results. Whether you are setting your eyes 
            on a new market or looking for ways to establish yourself as a thought leader in your industry, there's digital 
            marketing strategy for everything and anything you envision attaining.
            </p>
            <div id='content3_cards'>
                <div id='content3_card_item'>
                    <h1 id='content3_card_h1'>Why work with Aariva?</h1>
                    <p id='content3_card_p'>
                    You should prefer working with Aariva if you wish to ace in the digital race! Our digital experts will boost your brand's 
                    image and bring in the conversions that you always wished for. We are experts of the art of digital marketing with an 
                    experience of 5+ years in the industry. Till date, we have served more than 150 clients across the globe. Our team of 60+ 
                    passionate digital connoisseurs is all you need to establish a strong digital presence.
                    </p>
                </div>
                <div id='content3_card_item'>
                    <h1 id='content3_card_h1'>How much do your Digital Marketing services cost?</h1>
                    <p id='content3_card_p'>
                    As digital marketing services entail a wide range of factors such as social platforms, paid campaigns, etc., the charges vary. 
                    We chart a plan that is unique to your business.
                    </p>
                </div>
                <div id='content3_card_item'>
                    <h1 id='content3_card_h1'>How do we monitor the growth after doing Digital Marketing with us?</h1>
                    <p id='content3_card_p'>We will share a monthly report of all the activities and campaigns we undertake that 
                        will help you keep tabs on the growth of your brand.
                    </p>
                </div>
                <div id='content3_card_item'>
                    <h1 id='content3_card_h1'>What services do you provide with Digital Marketing?</h1>
                    <p id='content3_card_p'>
                    As a 360-degree digital marketing agency, we provide the following services -
                        PPC,
                        Analytics and conversion rate optimization,
                        Content creation and marketing,
                        Social media,
                        Email marketing,
                        Marketing automation SEO.
                    </p>
                </div>
                <div id='content3_card_item'>
                    <h1 id='content3_card_h1'>How Digital Marketing will help grow my business</h1>
                    <p id='content3_card_p'>
                    In digital marketing, it is easy to keep in touch with your group and public with the just single click. According to your 
                    business category, you can create the group or channels and keep the good posting infrequently to grow your business.
                    </p>
                </div>
                <div id='content3_card_item'>
                    <h1 id='content3_card_h1'>What is Digital Marketing?</h1>
                    <p id='content3_card_p'>
                    Digital marketing is a bit of a catch-all phrase to cover all the services we offer. There are likely all sorts of definitions, 
                    but in our case, it covers anything based online. This includes:
                        SEO,
                        PPC,
                        Analytics and conversion rate optimization,
                        Content creation and marketing,
                        Social media,
                        Email marketing,
                        Marketing automation SEO.
                    </p>
                </div>
            </div>
        </div>

        <div id='dm_content4'>
            <h1 id='dm_content4_h1'>Our Services Include</h1>
            <div id='dm_content4_cards'>
                <p id='dm_content4_card_item'>Search Engine Optimzation</p>
                <p id='dm_content4_card_item'>Search Engine Marketing</p>
                <p id='dm_content4_card_item'>Social Media Marketing</p>
                <p id='dm_content4_card_item'>LinkedIn Profile Management</p>
                <p id='dm_content4_card_item'>Facebook Marketing</p>
                <p id='dm_content4_card_item'>Influencer Marketing</p>
                <p id='dm_content4_card_item'>Online Reputation Management</p>
                <p id='dm_content4_card_item'>Video Campaigns</p>
            </div>
            
        </div>
        

      </div>
  );
};

export default DigitalMarketing;