import React from 'react';

function Infrastructure() {
    return(
        <div id="infrastructure_container">

            <div id='infrastructure_header'>
                <h1 id='infrastructure_header_h1'> Infrastructure Support Services</h1>
                <p id='infrastructure_header_p'>
                    IT Infrastructure is the key for every organization to sustain business growth in today's dynamic and competitive market. 
                    Organizations are required to optimize their ICT strategies to maintain the synergy between business units and the technology landscape. 
                    To achieve this IT strategy is created and updated on a continuous basis. Aariva Infrastructure Support and Consulting services help organizations' IT units,
                    align with the real business needs - driving down IT running costs.
                </p>
            </div>

            {/* cards */}
            <div id='infrastructure_cards_container'>
                <div id='infrastructure_card_item_container'>
                <h5 id='infrastructure_card_h5'>Datacenter Support Service</h5>
                    <div id='infrastructure_card_item'>
                        <p id='infrastructure_card_p'>DC Infrastructure Management</p>
                        <p id='infrastructure_card_p'>DC Migration and Transformation Services</p>
                        <p id='infrastructure_card_p'>Virtual Infrastructure and Cloud Managem</p>
                        <p id='infrastructure_card_p'>Hardware Support</p>
                    </div>
                </div>
                
                <div id='infrastructure_card_item_container'>
                <h5 id='infrastructure_card_h5'>Network Support Services</h5>
                    <div id='infrastructure_card_item' className='card2'>
                        <p id='infrastructure_card_p'>Enterprise and Telco Network Support</p>
                        <p id='infrastructure_card_p'>LAN/WAN Design and Implementation</p>
                        <p id='infrastructure_card_p'>Wireless Network Design and Implementation</p>
                        <p id='infrastructure_card_p'>Load Balancers and Field Support Services</p>
                    </div>
                </div>
                
                <div id='infrastructure_card_item_container'>
                <h5 id='infrastructure_card_h5'>Infrastructure Managed Solution</h5>
                    <div id='infrastructure_card_item' className='card3'>
                        <p id='infrastructure_card_p'>Network-DC-Security Infrastructure Management Services</p>
                        <p id='infrastructure_card_p'>Integrated Service Desk</p>
                        <p id='infrastructure_card_p'>Automation and Tooling Framework Management</p>
                        <p id='infrastructure_card_p'>Desktop Support</p>
                    </div>
                </div>
                
                <div id='infrastructure_card_item_container'>
                <h5 id='infrastructure_card_h5'>Cyber Security</h5>
                    <div id='infrastructure_card_item' className='card4'>
                        <p id='infrastructure_card_p'>Security Incident Management</p>
                        <p id='infrastructure_card_p'>Security Infrastructure Management</p>
                        <p id='infrastructure_card_p'>Anti Phishing and Anti Malware services</p>
                        <p id='infrastructure_card_p'>Managed End point Security and more</p>
                    </div>
                </div>
            </div>

            <div id='infrastructure_consulting'>
                <h1 id='infrastructure_consulting_h1'>Consulting Services</h1>
                <div id='infrastructure_consulting_cards'>
                    <div id='infrastructure_consulting_card'>
                        <p id='infrastructure_consulting_card_p'>IT Infrastructure Audits - Network, Security, Compliance, Cloud Readiness, Data Centre</p>
                    </div>
                    <div id='infrastructure_consulting_card'>
                        <p id='infrastructure_consulting_card_p'>IT Infrastructure Management Processes Assessments and Optimization</p>
                    </div>
                    <div id='infrastructure_consulting_card'>
                        <p id='infrastructure_consulting_card_p'>Infrastructure Solution Design and Consulting</p>
                    </div>
                    <div id='infrastructure_consulting_card'>
                        <p id='infrastructure_consulting_card_p'>Cyber Security Assessment and Consulting</p>
                    </div>
                    <div id='infrastructure_consulting_card'>
                        <p id='infrastructure_consulting_card_p'>Project and Programme Management Consulting</p>
                    </div>
                </div>
            </div>

            <div id='ims_content'>
                <h1 id='ims_h1'>Infrastructure Managed Services</h1>
                <p id='ims_p'>IT infrastructure has been traditionally managed using a combination of people, processes, tools and technology. 
                    Our unique intelligent infrastructure management solution has been designed to reduce IT operations budgets, 
                    increase productivity and optimize IT management processes through disruptive automation frameworks.
                </p>
                <div id='ims_cards'>
                    <div id='ims_card'>
                        <p id='ims_cars_p'>Integrated Service Desk</p>
                    </div>
                    <div id='ims_card'>
                        <p id='ims_cars_p'>Remote Infrastructure Management</p>
                    </div>
                    <div id='ims_card'>
                        <p id='ims_cars_p'>Automated Infrastructure Management</p>
                    </div>
                </div>
                <p id='ims_p'>
                From monitoring your servers across the globe, troubleshooting carrier network equipment in South Africa or deploying software 
                across your desktop environment we have a full range of consultants, processes, tools and automation strategies to execute 
                your IT services with flexible pricing options.
                </p>
                <div id='ims_cards2'>
                    <div id='ims_card'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" id='ims_card_svg' class="w-12 h-12 mx-auto mb-3">
                        <path fill-rule="evenodd" d="M2.25 5.25a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3V15a3 3 0 0 1-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 0 1-.53 1.28h-9a.75.75 0 0 1-.53-1.28l.621-.622a2.25 2.25 0 0 0 .659-1.59V18h-3a3 3 0 0 1-3-3V5.25Zm1.5 0v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5Z" clip-rule="evenodd" />
                        </svg>
                        <p id='ims_cars_p'>Per Device</p>
                    </div>
                    <div id='ims_card'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" id='ims_card_svg' class="w-12 h-12 mx-auto mb-3">
                        <path fill-rule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clip-rule="evenodd" />
                        <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                        </svg>
                        <p id='ims_cars_p'>Per User</p>
                    </div>
                    <div id='ims_card'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" id='ims_card_svg' class="w-12 h-12 mx-auto mb-3">
                        <path fill-rule="evenodd" d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.25 6a.75.75 0 0 0-1.5 0v4.94l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V9.75Z" clip-rule="evenodd" />
                        </svg>
                        <p id='ims_cars_p'>Per Port</p>
                    </div>
                    <div id='ims_card'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" id='ims_card_svg' class="w-12 h-12 mx-auto mb-3">
                        <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
                        <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clip-rule="evenodd" />
                        </svg>
                        <p id='ims_cars_p'>Economies of Scale Volume Discounts</p>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Infrastructure;