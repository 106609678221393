import React from 'react';


function RPAsolutions() {
  return(
      <div id='RPAsol_container'>

          <div id='RPAsol_header'>
              <h1 id='RPAsol_header_h1'>RPA solutions</h1>
              <p id='RPAsol_header_p'>
              Aariva's Intelligent Robotic Process Automation Solution provides a unified 
              platform which comes bundled with capabilities to automate front office, middle office, back office and IT operations.
              </p>
              <p id='RPAsol_header_p'>
              Chatbots, Intelligent Automation bots, AI and Machine Learning capabilities help clients reduce costs, 
              improve response time, grow business and achieve compliance. Our Solution provides ready action Bots 
              and development environment to build new Bots.
              </p>
          </div>

          <div id='RPAsol_content'>
            <p id='RPAsol_content_p'>Aariva's IT Automation automates repetitive and routine IT tasks to reduce downtime and 
              improve incident response and resolution. It offers advance capabilities like RPA, Chatbot, 
              Machine Learning, and ready Plugins for rapid API integrations.
            </p>
            <p id='RPAsol_content_p2'>
              The benefits of IT Automation are compelling - automation improves accountability, efficiency and predictability, 
              while reducing cost, variability and risk.
            </p>
            <p id='RPAsol_content_p2'>
              Library of 300+ ready actions for IT systems to automate common IT processes across systems like Windows, 
              VMware, Cisco, SAP, Oracle Remedy, Salesforce, Exchange, service Now etc.
            </p>
            <p id='RPAsol_content_p2'>
              Virtual assistant for IT, HR, ERP, CRM and Core business applications to serve existing customers and prospects
            </p>
          </div>

          <div id='RPAsol_benefits'>
            <h1 id='RPAsol_benefits_h1'>Benefits of our RPA solution</h1>
            <div id='RPAsol_benefits_p_container'>
              <p>Cognitive Capabilities (AI, ML, Chatbot)</p>
              <p>High level of Security and Compliance</p>
              <p>Non-intrusive implementation</p>
              <p>Best talent doing creative work</p>
              <p>Easily scaled operations</p>
              <p>Independent of your environment</p>
              <p>Central access control</p>
              <p>Operations deep insight and analytics</p>
              <p>No Coding Required</p>
              <p>Extensive Banking, Insurance Domain Experience</p>
              <p>80% reduction in cost of operations</p>
            </div>
          </div>

      </div>
  );
};

export default RPAsolutions;