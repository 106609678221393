import React from 'react';


function WebsiteDevelopment() {
  return(
      <div id='webdev_container'>

          <div id='webdev_header'>
              <h1 id='webdev_header_h1'>Website Design & Development</h1>
              <p id='webdev_header_p'>
              Increase demand of your products with the best web design and development company.
              </p>
              <p id='webdev_header_p'>
              Your website is your window to the world. Your website must be both effective and indelible. 
              We combine our slick digital applications along with unique strong visual identity to create an impressive customer-centric 
              responsive website at an affordable rate. The website we design are easy to navigate, edit, update and with fast loading to make it user friendly.
              </p>
          </div>

        <div id='webdev_services'>
            <h1 id='webdev_h1'>We offer the following services</h1>
            <div id='webdev_services_cards'>
                <div id='webdev_card_item'>
                    <h1 id='webdev_card_h1'>
                    Cross Browser & Mobile Friendly
                    </h1>
                    <p id='webdev_card_p'>We develop websites that are cross browser and mobile compatible.</p>
                </div>
                <div id='webdev_card_item'>
                    <h1 id='webdev_card_h1'>
                    SEO Friendly
                    </h1>
                    <p id='webdev_card_p'>We develop Search Engine Optimized websites</p>
                </div>
                <div id='webdev_card_item'>
                    <h1 id='webdev_card_h1'>
                    Customized and User Friendly
                    </h1>
                    <p id='webdev_card_p'>We design custom made websites that suits our client's requirements.</p>
                </div>
                <div id='webdev_card_item'>
                    <h1 id='webdev_card_h1'>
                    Cloud Hosting
                    </h1>
                    <p id='webdev_card_p'>Our websites have 100% uptime and we use cloud hosting.</p>
                </div>
                <div id='webdev_card_item'>
                    <h1 id='webdev_card_h1'>
                    Ecommerce Website
                    </h1>
                    <p id='webdev_card_p'>We custom made ecommerce website, extension modules and shopping cart software that suits your business needs.</p>
                </div>
            </div>
        </div>

      </div>
  );
};

export default WebsiteDevelopment;