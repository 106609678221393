import React from 'react';

function AdvisoryServices() {
    return(
        <div className="bg-white w-full pt-40 mx-auto max-w-2xl py-32 sm:py-48 lg:py-56" id="advisory_services">
            <h1 className="text-5xl text-center font-bold tracking-tight text-gray-900 sm:text-6xl"> 
            AdvisoryServices
            </h1>
        </div>
    );
}

export default AdvisoryServices;