import React from 'react';
import SAPlogo from '../Assets/SAP.png';
import Oraclelogo from '../Assets/Oracle.png';
import Javalogo from '../Assets/java.png';
import Phplogo from '../Assets/Php.png';
import Clogo from '../Assets/c.png';
import Pylogo from '../Assets/python.png';

function Applications() {
    return(
        <div id='applications_container'>

            <div id='applications_header'>
                <h1 id='applications_header_h1'>Application Development and Support Services</h1>
                <p id='applications_header_p'>
                Aariva's Application Development and Support Teams has expertise that spans across technology domains, 
                enabling us to develop web, mobile or complex bespoke software solutions.  Using our integrated service desk and application support teams, 
                we can deliver cost effective, tailor-made application support for any our client's requirements.
                </p>
            </div>

            {/* cards */}
            <div id='applications_cards_container'>

                <div id='applications_card_item_container'>
                    <div id='applications_card_item' className='card1'>
                        <h5 id='applications_card_item_h5'>Application Support and Maintenance</h5>
                    </div>
                    <p id='applications_card_item_p'>
                        Whether the application resides on the desktop or in your datacentre, Aariva has unique product offerings which bring significant benefits through 
                        integrated solutions to address key business drivers for application support.
                    </p>
                </div>

                <div id='applications_card_item_container'>
                    <div id='applications_card_item' className='card5'>
                        <h5 id='applications_card_item_h5'>Software & Application Testing</h5>
                    </div>
                    <div id='softapp_tags_container' className='flex justify-center items-center'>
                        <p id='softapp_tags_p' className='bg-red-700 p-2 m-2 rounded-md text-white shadow-lg ' >Functional</p>
                        <p id='softapp_tags_p' className='bg-red-700 p-2 m-2 rounded-md text-white shadow-lg '>Stress</p>
                        <p id='softapp_tags_p' className='bg-red-700 p-2 m-2 rounded-md text-white shadow-lg '>Automation</p>
                        <p id='softapp_tags_p' className='bg-red-700 p-2 m-2 rounded-md text-white shadow-lg '>Security</p>
                    </div>
                    <p id='applications_card_item_p'>
                    Imagine being able to have a quality assurance team at your fingertips, working remotely to 
                    contribute to your project's quality and success. Testing Service as staff augmentation or managed 
                    service is great idea for businesses of any size because efficiency means faster project delivery and reduced project costs.
                    </p>
                </div>

                <div id='applications_card_item_container'>
                    <div id='applications_card_item' className='card2'>
                        <img id='SAP_img' className="h-16 w-32 mr-4 shadow-md hover:scale-125 transition duration-500" src={SAPlogo} alt="Your Company"></img>
                        <h5 id='applications_card_item_h5'>SAP Support & Maintenance</h5>
                    </div>
                    <p id='applications_card_item_p'>
                        Aariva offers comprehensive SAP consulting services and 
                        proven project management expertise that helps our clients transform the way they interact with their customers, 
                        suppliers and employees. Coupled with deep industry-specific knowledge, strong execution methodologies, 
                        extensive hands-on experience, we can assist our clients during plan, build and operating phases of their SAP projects.
                    </p>
                </div>
                
                <div id='applications_card_item_container'>
                    <div id='applications_card_item' className='card3'>
                        <img id='oracle_img' className="h-16 w-48 mr-4 shadow-md hover:scale-110 transition duration-500" src={Oraclelogo} alt="Your Company"></img>
                        <h5 id='applications_card_item_h5'>Oracle Support & Maintenance</h5>
                    </div>
                    <p id='applications_card_item_p'>
                        A certified Oracle partner enriched with cloud capability to expand its services, 
                        Aariva has experienced engineers to deliver on analysis, implementation, integration and
                        support of Oracle products and solutions. We have consultants across domains, products and technologies. 
                        So, whether its Oracle E-Business Suite, Oracle Cloud, PeopleSoft or JD Edwards we can structure a tailor-made solution to align to your business needs.
                    </p>
                </div>
                
                <div id='applications_card_item_container'>
                    <div id='applications_card_item' className='card4'>
                        <h5 id='applications_card_item_h5'>Custom Application Development</h5>
                    </div>
                    <div className='flex justify-center items-center'>
                        <img id='php_img' className="h-16 w-48 mr-4 shadow-md hover:scale-125 transition duration-500" src={Phplogo} alt="Your Company"></img>
                        <img id='c_img' className="h-32 w-32 mr-4 hover:scale-125 transition duration-500" src={Clogo} alt="Your Company"></img>
                        <img id='python_img' className="h-32 w-32 mr-4 hover:scale-125 transition duration-500" src={Pylogo} alt="Your Company"></img>
                        <img id='java_img' className="h-32 w-32 mr-4 hover:scale-125 transition duration-500" src={Javalogo} alt="Your Company"></img>
                    </div>
                    <p id='applications_card_item_p'>
                        Our core strength lies in its programming abilities. Our robust software development processes 
                        are built using agile processes, where requirements and solutions evolve through internal collaboration between self-organizing cross-functional teams.
                        Quality output is guaranteed by the robust documentation and development processes that are entrenched
                        into the ethos of our valuable team members. From idea to getting your product market ready, 
                        and everything in between, we provide a complete range of UX, design, engineering and documentation services. 
                        Our solutions are founded on design that thinks of the user first while leveraging proprietary, open source, 
                        cloud, Big Data and IoT based technologies to deliver context-aware, adaptive experiences.
                    </p>
                </div>
                
            </div>

            {/* <div id='advantages_container'>
                <h1 className='text-black text-center p-8 text-2xl'>Advantages of Teaming up with Exelligent Technologies</h1>
                    <div className='justify-center items-center align-middle flex pb-16 p-8'>
                        <p className='bg-blue-700 p-10 m-2 w-72 text-center text-lg rounded-md text-white shadow-lg hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500'>Advanced Technologies</p>
                        <p className='bg-blue-600 p-10 m-2 w-72 text-center text-lg rounded-md text-white shadow-lg hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500'>Advanced Security Services</p>
                        <p className='bg-blue-500 p-10 m-2 w-72 text-center text-lg rounded-md text-white shadow-lg hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500'>Solid QA Processes</p>
                        <p className='bg-blue-400 p-10 m-2 w-72 text-center text-lg rounded-md text-white shadow-lg hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500'>Robust Development Processes</p>
                        <p className='bg-blue-400 p-10 m-2 w-72 text-center text-lg rounded-md text-white shadow-lg hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500'>Agile Development</p>
                        <p className='bg-blue-400 p-10 m-2 w-72 text-center text-lg rounded-md text-white shadow-lg hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500'>Optimum Pricing</p>    
                    </div>
            </div> */}
            
        </div>
    );
}

export default Applications;