import React from 'react';
import beee from '../Assets/BEE.pdf'

function BEEEcertificate () {
 return (
  <div id='pdf_container' >
     <iframe id='pdf_item' title='beeecertificate' src={beee} /> 
  </div>
 );
};
export default BEEEcertificate;