import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/ContactUs';
import Navbar2 from './Components/Navbar2';
import Footer from './Components/Footer';
import Infrastructure from './Pages/Infrastructure';
import Applications from './Pages/Applications';
import ResourceAugmentation from './Pages/ResourceAugmentation';
import AdvisoryServices from './Pages/AdvisoryServices';
import IntegratedInfrastructureManagement from './Pages/IntegratedInfrastrucureManagement';
import RPAsolutions from './Pages/RPAsolutions';
import RPAoncloud from './Pages/RPAoncloud';
import RPAforindustries from './Pages/RPAforindustries';
import DigitalMarketing from './Pages/DigitalMarketing';
import WebsiteDevelopment from './Pages/WebsiteDevelopment';
import ScrollToTop from './Components/ScrollToTop';
import BEEEcertificate from './Pages/BEEEcertificate';

function App() {
    return (
        <Router>
            
            <ScrollToTop />
            <Navbar2 />
            <Routes>
                <Route path="/home" element={<Home />} />
                
                {/* services */}
                <Route path="/infrastructure" element={<Infrastructure />} />
                <Route path="/applications" element={<Applications />} />
                <Route path="/resource_augmentation" element={<ResourceAugmentation />} />
                <Route path="/advisory_services" element={<AdvisoryServices />} />

                {/* Solutions */}
                <Route path="/integratedinfrastructuremanagement" element={<IntegratedInfrastructureManagement />} />
                <Route path="/rpasolutions" element={<RPAsolutions />} />
                <Route path="/rpaoncloud" element={<RPAoncloud />} />
                <Route path="/rpaforindustries" element={<RPAforindustries />} />
                <Route path="/digitalmarketing" element={<DigitalMarketing />} />
                <Route path="/websitedevelopment" element={<WebsiteDevelopment />} />

                <Route path="/about" element={<About />} />

                <Route path="/contact" element={<Contact />} />

                <Route path="/beecertificate" element={<BEEEcertificate />} />

                {/* default route */}
                <Route path="*" element={<Home />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
