import React from 'react';
import resourcing_header_img from '../Assets/resourcingHeader.png'
import bank from '../Assets/res_aut/icons8-bank-96.png'
import insurance from '../Assets/res_aut/icons8-insurance-96.png'
import manufacturing from '../Assets/res_aut/icons8-manufacturing-96.png'
import automobile from '../Assets/res_aut/icons8-vehicle-96.png'
import health from '../Assets/res_aut/icons8-health-96.png'
import telecom from '../Assets/res_aut/icons8-radio-tower-96.png'
import mining from '../Assets/res_aut/icons8-mining-96.png'
import education from '../Assets/res_aut/icons8-education-100.png'

function ResourceAugmentation() {
    return(
        <div id='resourceaugmentation_container'>

            <div id='resourceaugmentation_header'>
                <h1 id='resourceaugmentation_header_h1'>Resource Augmentation</h1>
            </div>

            <div id='resourceaugmentation_header_p_img'>
                <p id='resourceaugmentation_header_p'>
                    Sometimes, it can be difficult to decide between augmenting staff or hiring full-time/part-time employees, 
                    but augmenting can really help you satisfy your short-term and long-term staffing needs so that you always deliver your IT solutions on time.
                <br/>
                    Aariva has a CoE pool of talent with niche technology experience to fulfil any of our client's immediate requirements.  
                    We specialise in acquiring and deploying reliable and knowledgeable technical resources in the required IT domains. 
                    Our focus in this area is to provide the best available Information Technology talent to organisation across industry verticals 
                    to either deliver complete projects or augment your existing ones.  Flexible pricing models allows us to deliver this service as a Managed Service.
                </p>
                <img id='resourceimg' className="h-96 w-auto hover:scale-110 transition duration-500 hover:shadow-lg shadow-lg" src={resourcing_header_img} alt="Your Company"></img>
            </div>

            <div id='resourceaugmentation_smallcards_container'>
                <div id='resourceaugmentation_smallcard'>
                    <h5 id='resourceaugmentation_smallcard_h5'>Financial</h5>
                    <img id='cards_imgs' className="h-18 justify-center align-middle items-center ml-10 mt-2 w-auto" src={bank} alt="Your Company"></img>
                </div>
                <div id='resourceaugmentation_smallcard'>
                    <h5 id='resourceaugmentation_smallcard_h5'>Insurance</h5>
                    <img id='cards_imgs' className="h-18 justify-center align-middle items-center ml-10 mt-4 w-auto" src={insurance} alt="Your Company"></img>
                </div>
                <div id='resourceaugmentation_smallcard'>
                    <h5 id='resourceaugmentation_smallcard_h5'>Manufacturing</h5>
                    <img id='cards_imgs' className="h-18 justify-center align-middle items-center ml-10 mt-4 w-auto" src={manufacturing} alt="Your Company"></img>
                </div>
                <div id='resourceaugmentation_smallcard'>
                    <h5 id='resourceaugmentation_smallcard_h5'>Automobiles</h5>
                    <img id='cards_imgs' className="h-18 justify-center align-middle items-center ml-10 mt-4 w-auto" src={automobile} alt="Your Company"></img>
                </div>
            </div>

            <div id='resourceaugmentation_smallcards_container' className='cards_container_2'>
                <div id='resourceaugmentation_smallcard'>
                    <h5 id='resourceaugmentation_smallcard_h5'>Healthcare</h5>
                    <img id='cards_imgs' className="h-18 justify-center align-middle items-center ml-10 mt-4 w-auto" src={health} alt="Your Company"></img>
                </div>
                <div id='resourceaugmentation_smallcard'>
                    <h5 id='resourceaugmentation_smallcard_h5'>	Telecommunication</h5>
                    <img id='cards_imgs' className="h-18 justify-center align-middle items-center ml-10 mt-4 w-auto" src={telecom} alt="Your Company"></img>
                </div>
                <div id='resourceaugmentation_smallcard'>
                    <h5 id='resourceaugmentation_smallcard_h5'>Mining</h5>
                    <img id='cards_imgs' className="h-18 justify-center align-middle items-center ml-10 mt-4 w-auto" src={mining} alt="Your Company"></img>
                </div>
                <div id='resourceaugmentation_smallcard'>
                    <h5 id='resourceaugmentation_smallcard_h5'>	Education</h5>
                    <img id='cards_imgs' className="h-18 justify-center align-middle items-center ml-10 mt-2 w-auto" src={education} alt="Your Company"></img>
                </div>
            </div>

            <div id='talentpool_container'>
                <h1 id='talentpoolh1' className='text-center text-black font-bold text-4xl pb-6'>Talent Pool</h1>
                <p id='talentpoolp' className=' text-left text-blue-gray-600 px-96 leading-8 text-slate-600  justify-center'>
                    Organizations today need to respond to rapidly changing business environments. 
                    As a result, these changes are happening at a faster rate than at any time in history. 
                    Staying ahead of the change is key to staying ahead of the pack.
                </p>
                <p id='talentpoolp' className=' text-left text-blue-gray-600 px-96 leading-8 text-slate-600  justify-center'>
                    Aariva
                    offers consulting services to organizations to deliver optimum business results. 
                    Our consultants use their global experience along-with local knowledge to deliver customized solutions to clients
                </p>
                <div id='talent_pool_cards_container' className='grid grid-cols-2 px-96 py-10 text-center'>
                    <p id='talentpool_card'>
                        Robotic Process Automation
                    </p>
                    <p id='talentpool_card'>
                        Governance, Risk and Compliance Services
                    </p>
                    <p id='talentpool_card'> 
                        Enterprise architecture
                    </p>
                    <p id='talentpool_card'> 
                        Security Consultants
                    </p>
                    <p id='talentpool_card'>
                        Infrastructure Consultants
                    </p>
                </div>
            </div>

            <div id='portfolios_content'>
            <h1  id='portfolios_h1'> Portfolios </h1>
                <div id='portfolios_cards_container'>
                    
                    <div id='portfolio_card_item_container'>
                    <h5 id='portfolios_card_h5'>Software Developers</h5>
                    <div id='portfolios_card_item' className='card1'>
                        <p id='portfolios_card_p'>•	Java</p>
                        <p id='portfolios_card_p'>•	Node JS</p>
                        <p id='portfolios_card_p'>•	Python</p>
                        <p id='portfolios_card_p'>•	.Net</p>
                        <p id='portfolios_card_p'>•	React JS</p>
                        <p id='portfolios_card_p'>•	Ruby</p>
                        <p id='portfolios_card_p'>•	MySQL</p>
                        <p id='portfolios_card_p'>•	Angular JS</p>
                        <p id='portfolios_card_p'>•	C#</p>
                        <p id='portfolios_card_p'>•	Oracle</p>
                    </div>
                    </div>

                    <div id='portfolio_card_item_container'>
                    <h5 id='portfolios_card_h5'>Enterprise</h5>
                    <div id='portfolios_card_item' className='card2'>
                        <p id='portfolios_card_p'>•	SAP</p>
                        <p id='portfolios_card_p'>•	Pega</p>
                        <p id='portfolios_card_p'>•	Oracle</p>
                        <p id='portfolios_card_p'>•	IFS</p>
                        <p id='portfolios_card_p'>•	Mainframe</p>
                        <p id='portfolios_card_p'>•	JD Edwards</p>
                        <p id='portfolios_card_p'>•	Microsoft Dynamics ERP</p>
                    </div>
                    </div>

                    <div id='portfolio_card_item_container'>
                    <h5 id='portfolios_card_h5'>Robotic Process Automation</h5>
                    <div id='portfolios_card_item' className='card5'>
                        <p id='portfolios_card_p'>•	Blue Prism</p>
                        <p id='portfolios_card_p'>•	UIpath</p>
                        <p id='portfolios_card_p'>•	Business & Process Architect</p>
                        <p id='portfolios_card_p'>•	Automation Anywhere</p>
                        <p id='portfolios_card_p'>•	RPA Consultants</p>
                        <p id='portfolios_card_p'>•	RPA Developer</p>
                    </div>
                    </div>

                    <div id='portfolio_card_item_container'>
                    <h5 id='portfolios_card_h5'>Cloud Consulting & Management</h5>
                    <div id='portfolios_card_item' className='card3'>
                        <p id='portfolios_card_p'>•	AWS</p>
                        <p id='portfolios_card_p'>•	Google</p>
                        <p id='portfolios_card_p'>•	Azure</p>
                    </div>
                    </div>

                    <div id='portfolio_card_item_container'>
                    <h5 id='portfolios_card_h5'>Software Testing</h5>
                    <div id='portfolios_card_item' className='card4'>
                        <p id='portfolios_card_p'>•	Selenium</p>
                        <p id='portfolios_card_p'>•	Manual and automated testing</p>
                        <p id='portfolios_card_p'>•	HPE-UFT</p>
                    </div>
                    </div>

                    <div id='portfolio_card_item_container'>
                    <h5 id='portfolios_card_h5'>Big Data Solutions</h5>
                    <div id='portfolios_card_item' className='card6'>
                        <p id='portfolios_card_p'>•	Business Analyst</p>
                        <p id='portfolios_card_p'>•	Data Scientests</p>
                        <p id='portfolios_card_p'>•	Database Administrators</p>
                        <p id='portfolios_card_p'>•	Data Analysts</p>
                        <p id='portfolios_card_p'>•	Data Engineers</p>
                        <p id='portfolios_card_p'>•	Developers</p>
                    </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default ResourceAugmentation;