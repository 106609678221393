import React from 'react';


function RPAoncloud() {
  return(
    <div id='RPAcloud_container'>

        <div id='RPAcloud_header'>
            <h1 id='RPAcloud_header_h1'>RPA on Cloud</h1>
            <p id='RPAcloud_header_p'>
            Aariva's RPA on cloud is first in the industry to offer RPA as a Service (RPAaaS). Our RPA as a Service is a matured, 
            complete SaaS solution with true multi-tenant and scalable architecture. While Robotic Process 
            Automation remains the cutting-edge technology to bring digital transformation, reduce cost and 
            increase efficiency, RPA on Cloud offers an added advantage of lower infrastructure cost, ease of deployment, 
            less maintenance and upgrade costs.
            </p>
        </div>

        <div id='RPAcloud_content'>
            <h1 id='RPA_content_h1'>RPA as a Service model enables secure and scalable automation at a very affordable cost.</h1>
            <div id='RPA_content_p_container'>
                <div id='RPAcloud_card_item'>
                    <h1 id='RPAcloud_card_h1'>Affordable RPA on Cloud</h1>
                    <p id='RPAcloud_card_p'>RPA on Cloud offers very flexible pricing with rapid automation for the enterprises. 
                        RPA on cloud provides highly scalable and accessible solution.
                    </p>
                </div>
                
                <div id='RPAcloud_card_item'>
                    <h1 id='RPAcloud_card_h1'>Low Cost of Ownership</h1>
                    <p id='RPAcloud_card_p'>RPA on Cloud solution saves huge cost of IT infrastructure and its maintenance with 
                        increased uptime. You do not have to pay high server hardware and software costs.
                    </p>
                </div>
                
                <div id='RPAcloud_card_item'>
                    <h1 id='RPAcloud_card_h1'>Scalability and Flexibility</h1>
                    <p id='RPAcloud_card_p'>RPA bots can be scaled up on demand any time when you need. High availability, 
                        scalability and security of RPA server, orchestrator and bot controller is 
                        taken care of in RPA on cloud offering.
                    </p>
                </div>
                
                <div id='RPAcloud_card_item'>
                    <h1 id='RPAcloud_card_h1'>Easy to Maintain</h1>
                    <p id='RPAcloud_card_p'>RPA on cloud solution frees you from constant on-premise maintenance cost and reduces
                        system downtime and costs for solution upgrade.
                    </p>
                </div>
                
                <div id='RPAcloud_card_item'>
                    <h1 id='RPAcloud_card_h1'>Quick ROI</h1>
                    <p id='RPAcloud_card_p'>RPA on Cloud is easy to deploy and has low turnaround time for 
                        implementation thus offering quick ROI realization.
                    </p>
                </div>
                
                <div id='RPAcloud_card_item'>
                    <h1 id='RPAcloud_card_h1'>High Security and Compliance</h1>
                    <p id='RPAcloud_card_p'>RPA Cloud is well tested and compliant with ISO/IEC 27001:2013 international standards 
                        and guidelines for security.
                    </p>
                </div>
                
            </div>
        </div>

        <div id='RPAcloud_differentiators'>
            <h1 id='RPAcloud_differentiators_h1'>RPA on Cloud Differentiators</h1>
            <div>
                <p id='RPAcloud_differentiators_p' >AI Driven Platform</p>
                <p id='RPAcloud_differentiators_p' >10x Faster Data Processing</p>
                <p id='RPAcloud_differentiators_p' >On Demand for Peak Hours</p>
                <p id='RPAcloud_differentiators_p' >Multi-threading RPA Bots</p>
            </div>
        </div>

    </div>
      
  );
};

export default RPAoncloud;