import React from 'react';
import { Link } from 'react-router-dom';
import ipadimg from '../Assets/ipad.png'
import pic1 from '../Assets/whatwedo/Picture1.png'
import pic2 from '../Assets/whatwedo/Picture2.png'
import pic3 from '../Assets/whatwedo/Picture3.png'
import pic4 from '../Assets/whatwedo/Picture4.png'


function Home() {

    return(
        <div id="home_container">
                    
            <div id='home_header'>
                <h1 id='home_header_h1'>About Aariva</h1>

                <div id='ipad_p_button_container' className='grid grid-cols-2 ml-32 mt-8'>
                    <div>
                        <p id='home_header_p'>
                            Aariva is a Level 1 BBBEE Company started in 2019, headquartered in Johannesburg South Africa and having offshore presence. 
                            We also have operational presence in all the major cities in South Africa
                            - Johannesburg, Cape Town, Durban, Bloemfontein, Port Elizabeth and East London.
                            We hold a 100% shareholding of Aariva Pvt Limited in India.

                        </p>
                        <p id='home_header_p'>
                            Aariva specialises in products and services across major IT verticals including  Application Development, 
                            Oracle, SAP, Infrastructure Support and consulting, Cyber Security, Block Chain application development, IoT, Robotic process Automation, SDWAN etc.
                        </p>
                         {/* <div id='home_buttons_container'>
                            <Link id='button_blue' to='/beecertificate'> Our Solutions </Link>
                            <Link id='button_slate'>
                                Free Consultation
                                <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                </svg>
                            </Link>
                        </div>  */}
                    </div>
                    <img id='ipadimg' className='w-96 h-96 mt-10 ml-12 hover:scale-125 transition duration-500' src={ipadimg} alt='ipad'></img>
                </div>
                

            </div>
            

            {/* cards */}
            <div id='home_cards_container'>
                <div id='home_card_item_container'>
                    <div id='home_card_item' className='card1'>
                        <h5 id='home_card_h5'>Intergrated Infastructure Management</h5>
                    </div>
                    <p id='home_card_p'>
                        Aariva ISD (Integrated Service Desk) providing solution provides seamless and 
                        consistent single point of contact for all infrastructure and applications Incidents, Changes, Problems and Service Requests.
                    </p>
                </div>
                <div id='home_card_item_container'>
                    <div id='home_card_item' className='card1'>
                        <h5 id='home_card_h5'>Robotic Process Automation Solutions</h5>
                    </div>
                    <p id='home_card_p'>
                    Aariva Intelligent Robotic Process Automation Solution provides a unified platform which comes bundled 
                    with capabilities to automate front office, middle office, back office and IT operations.
                    </p>
                </div>
                <div id='home_card_item_container'>
                    <div id='home_card_item' className='card1'>
                        <h5 id='home_card_h5'>Digital Excellence</h5>
                    </div>
                    <p id='home_card_p'>
                        We use our digital expertise to build your brand and streamline your online presence. Our mission in this is twofold- 
                        first to identify and marshal the right digital instruments and second, 
                        train the spotlight on the services that will get them the desired results.
                    </p>
                </div>
            
            </div>

            <div id='home_wwd'>
                <h1 id='home_wwd_h1'>What we do?</h1>
                <div id='wwd_items'>
                    <div id='wwd_item'>
                        {/* <svg id='wwdsvg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="w-12 h-12">
                        <path fill-rule="evenodd" d="M4.5 9.75a6 6 0 0 1 11.573-2.226 3.75 3.75 0 0 1 4.133 4.303A4.5 4.5 0 0 1 18 20.25H6.75a5.25 5.25 0 0 1-2.23-10.004 6.072 6.072 0 0 1-.02-.496Z" clip-rule="evenodd" />
                        </svg> */}
                        <img src={pic1} alt='what we do'></img>
                        {/* <p id='home_wwd_p'>Infrastructure</p> */}
                    </div>
                    <div id='wwd_item'>
                        {/* <svg id='wwdsvg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="w-12 h-12">
                        <path d="M10.5 18.75a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" />
                        <path fill-rule="evenodd" d="M8.625.75A3.375 3.375 0 0 0 5.25 4.125v15.75a3.375 3.375 0 0 0 3.375 3.375h6.75a3.375 3.375 0 0 0 3.375-3.375V4.125A3.375 3.375 0 0 0 15.375.75h-6.75ZM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 0 1 7.5 19.875V4.125Z" clip-rule="evenodd" />
                        </svg> */}
                        <img src={pic2} alt='what we do'></img>
                        {/* <p id='home_wwd_p'>Applications</p> */}
                    </div>
                    <div id='wwd_item'>
                        {/* <svg id='wwdsvg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="w-12 h-12">
                        <path d="M17.004 10.407c.138.435-.216.842-.672.842h-3.465a.75.75 0 0 1-.65-.375l-1.732-3c-.229-.396-.053-.907.393-1.004a5.252 5.252 0 0 1 6.126 3.537ZM8.12 8.464c.307-.338.838-.235 1.066.16l1.732 3a.75.75 0 0 1 0 .75l-1.732 3c-.229.397-.76.5-1.067.161A5.23 5.23 0 0 1 6.75 12a5.23 5.23 0 0 1 1.37-3.536ZM10.878 17.13c-.447-.098-.623-.608-.394-1.004l1.733-3.002a.75.75 0 0 1 .65-.375h3.465c.457 0 .81.407.672.842a5.252 5.252 0 0 1-6.126 3.539Z" />
                        <path fill-rule="evenodd" d="M21 12.75a.75.75 0 1 0 0-1.5h-.783a8.22 8.22 0 0 0-.237-1.357l.734-.267a.75.75 0 1 0-.513-1.41l-.735.268a8.24 8.24 0 0 0-.689-1.192l.6-.503a.75.75 0 1 0-.964-1.149l-.6.504a8.3 8.3 0 0 0-1.054-.885l.391-.678a.75.75 0 1 0-1.299-.75l-.39.676a8.188 8.188 0 0 0-1.295-.47l.136-.77a.75.75 0 0 0-1.477-.26l-.136.77a8.36 8.36 0 0 0-1.377 0l-.136-.77a.75.75 0 1 0-1.477.26l.136.77c-.448.121-.88.28-1.294.47l-.39-.676a.75.75 0 0 0-1.3.75l.392.678a8.29 8.29 0 0 0-1.054.885l-.6-.504a.75.75 0 1 0-.965 1.149l.6.503a8.243 8.243 0 0 0-.689 1.192L3.8 8.216a.75.75 0 1 0-.513 1.41l.735.267a8.222 8.222 0 0 0-.238 1.356h-.783a.75.75 0 0 0 0 1.5h.783c.042.464.122.917.238 1.356l-.735.268a.75.75 0 0 0 .513 1.41l.735-.268c.197.417.428.816.69 1.191l-.6.504a.75.75 0 0 0 .963 1.15l.601-.505c.326.323.679.62 1.054.885l-.392.68a.75.75 0 0 0 1.3.75l.39-.679c.414.192.847.35 1.294.471l-.136.77a.75.75 0 0 0 1.477.261l.137-.772a8.332 8.332 0 0 0 1.376 0l.136.772a.75.75 0 1 0 1.477-.26l-.136-.771a8.19 8.19 0 0 0 1.294-.47l.391.677a.75.75 0 0 0 1.3-.75l-.393-.679a8.29 8.29 0 0 0 1.054-.885l.601.504a.75.75 0 0 0 .964-1.15l-.6-.503c.261-.375.492-.774.69-1.191l.735.267a.75.75 0 1 0 .512-1.41l-.734-.267c.115-.439.195-.892.237-1.356h.784Zm-2.657-3.06a6.744 6.744 0 0 0-1.19-2.053 6.784 6.784 0 0 0-1.82-1.51A6.705 6.705 0 0 0 12 5.25a6.8 6.8 0 0 0-1.225.11 6.7 6.7 0 0 0-2.15.793 6.784 6.784 0 0 0-2.952 3.489.76.76 0 0 1-.036.098A6.74 6.74 0 0 0 5.251 12a6.74 6.74 0 0 0 3.366 5.842l.009.005a6.704 6.704 0 0 0 2.18.798l.022.003a6.792 6.792 0 0 0 2.368-.004 6.704 6.704 0 0 0 2.205-.811 6.785 6.785 0 0 0 1.762-1.484l.009-.01.009-.01a6.743 6.743 0 0 0 1.18-2.066c.253-.707.39-1.469.39-2.263a6.74 6.74 0 0 0-.408-2.309Z" clip-rule="evenodd" />
                        </svg> */}
                        <img src={pic3} alt='what we do'></img>
                        {/* <p id='home_wwd_p'>Robotic Process Automation</p> */}
                    </div>
                    <div id='wwd_item'>
                        {/* <svg id='wwdsvg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="w-12 h-12">
                        <path fill-rule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clip-rule="evenodd" />
                        <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                        </svg> */}
                        <img src={pic4} alt='what we do'></img>
                        {/* <p id='home_wwd_p'>Resourcing Staff Augmentation</p> */}
                    </div>
                </div>
            </div>

                       
        </div>
    );
}

export default Home;