import React from 'react';

function Contact() {
    return(
        <div id='contact_container'>
            <div id='contact_header'>
                <h1 id='contact_header_h1'>Contact Us</h1>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 mx-auto">
                <path fill-rule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd" />
                </svg>
                <p id='contact_header_p'>84 Roosevelt Avenue,</p>
                <p id='contact_header_p'>Wendywood Ext1,</p>
                <p id='contact_header_p'>Sandton, 2090</p>
                
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 mt-10 mx-auto">
                <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
                </svg>
                <p id='contact_header_p'> +27 10 541 0089</p>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 mt-10 mx-auto">
                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>
                <p id='contact_header_p'>ENQUIRIES: sales@aariva.com</p>
                <p id='contact_header_p'>SUPPORT: servicedesk@aariva.com</p>

                
            </div>

            <div id='mapresponsive'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2608.2718988692723!2d28.08201161885931!3d-26.0778652206715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9572ebddf2d639%3A0x464be7fd8ae20012!2s84%20Roosevelt%20Ave%2C%20Wendywood%2C%20Sandton%2C%202148!5e0!3m2!1sen!2sza!4v1705597160841!5m2!1sen!2sza" 
                    
                    id='mapframe'
                    width="1000" 
                    height="600 " 
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Map"
                    >
                    
                    </iframe>
            </div>

        </div>
    );
}

export default Contact;