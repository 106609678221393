import React from 'react';
import AIMimg from '../Assets/IIM.png'



function IntegratedInfrastructureManagement() {
  return(
      <div id='IIM_container'>

          <div id='IIM_header'>
              <h1 id='IIM_header_h1'>Integrated Infrastructure Management</h1>
              
          </div>

          <div id='IIM_content'>
            <div id='IIM_intservicedesk'>
              <h1 id='intservicedesk_h1'>Integrated Service Desk</h1>
              <p id='intservicedesk_p'>
              With the increasing global expansion and the technology driven workforce for an organization need to enhance Employee 
              experience and satisfaction while complying with standard practices, within the decreasing operational budgets. 
              Aariva ISD (Integrated Service Desk) providing solution provides seamless and consistent single 
              point of contact for all infrastructure and applications Incidents, Changes, Problems and Service Requests.
              </p>
              <div id='servicedesk_cards'>
                <h1 id='servicedesk_card_h'>What this means to our clients</h1>
                <p id='servicedesk_card_p'>24X7 Single Point of contact for all Infrastructure & Application issues</p>
                <p id='servicedesk_card_p'>First Line TAC (Technical Assistance center) to ensure quick resolutions.</p>
                <p id='servicedesk_card_p'>Issues resolved well within SLA's.</p>
                <p id='servicedesk_card_p'>Efficient Change management, with quick service Restoration</p>
              </div>
              <div id='servicedesk_cards'>
                <h1 id='servicedesk_card_h'>Why Aariva Technologies Integrated Service Desk Solution</h1>
                <p id='servicedesk_card_p'>Single point of contact for all Application and Infrastructure related issues.</p>
                <p id='servicedesk_card_p'>Provide Level 1, Level 2 & level 3 support for Applications & infrastructure related issues.</p>
                <p id='servicedesk_card_p'>
                  Integrated service desk operations are delivered through a combination of People, Process, Tools and 
                  Automation providing efficient and cost effective First Line support.
                </p>
                <p id='servicedesk_card_p'>Guided and governed by ITIL® standards</p>
                <p id='servicedesk_card_p'>Certified teams at your service</p>
              </div>
            </div>  
          </div>

          <div id='IIM_remoteinfmanage'>
            <h1 id='remoteinfmanage_h1'>Remote Infrastructure Management</h1>
            <p id='remoteinfmanage_p'>
              Infrastructure is the heart of any Business Organization. Organizations are extensively dependent on the IT infrastructure 
              in the competitive business world. It becomes extremely important to ensure that organizations are equipped with technology 
              experts who not only ensure the lights are on for the entire IT assets, but are also focused towards optimizing IT infrastructure and operations. 
              Aariva technologies Automated Remote Infrastructure Management Services provide a one stop solution to organization for managing and optimizing 
              there entire IT assets including workstations, servers, storage, networking, middleware, database, Security, Cloud and enterprise applications.
            </p>
          </div>

          <div id='IIM_autoinfmanage'>
            <h1 id='autoinfmanage_h1'>Automated Infrastructure Management</h1>
            <p id='autoinfmanage_p'>
              Aariva's Automated Infrastructure Management (AIM) is a construct for our NOC and SOC Automation solutions. 
              This helps to mitigate the challenges faced while managing the NOC and SOC in traditional way. In an Automated NOC or SOC environment, 
              our Virtual Engineers replace the Service desk, Level 1 and some part of Level 2 teams to help drive down your costs, 
              increase customer satisfaction and decrease turn-around times.
            </p>
            <img id='AIMimg' src={AIMimg} alt='AIMimg'></img>
          </div>

      </div>
  );
}

export default IntegratedInfrastructureManagement;