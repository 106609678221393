import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logo from '../Assets/logo2.png';
import { Link } from 'react-router-dom';
 
const navListMenuItems = [
  {
    title: "Infrastructure",
    description: "Infrastructure Support and Consulting services.",
    link: '/infrastructure'
  },
  {
    title: "Applications",
    description: " Tailor-made Application Support.",
    link: '/applications'
  },
  {
    title: "Resource Augmentation",
    description: "Satisfy your staffing needs.",
    link: '/resource_augmentation'
  },
  // {
  //   title: "Advisory Services",
  //   description: "Learn how we can help you achieve your goals.",
  //   link: '/advisory_services'
  // },
];
 
function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = navListMenuItems.map(
    ({ link, title, description }, key) => (
      <Link to={link}>
        <MenuItem className="flex items-center gap-3 rounded-lg px-4 hover:shadow-md hover:rounded-md transition duration-300">
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-sm font-bold"
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium text-blue-gray-500"
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </Link>
    ),
  );
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Services
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

const navListMenuItems2 = [
  {
    title: "Integrated Infrastructure Management",
    description: "Remote and Automated infrastructure management.",
    link: '/integratedinfrastructuremanagement'
  },
  {
    title: "RPA Solutions",
    description: "Robotic Process Automation Solution.",
    link: '/rpasolutions'
  },
  {
    title: "RPA on Cloud",
    description: "RPA as a Service.",
    link: '/rpaoncloud'
  },
  {
    title: "RPA for Industries",
    description: "Banking, Insurance, Telecommunication and more.",
    link: '/rpaforindustries'
  },
  {
    title: "Digital Marketing",
    description: "Build your brand.",
    link: '/digitalmarketing'
  },
  {
    title: "Website Development",
    description: "Your window to the world.",
    link: '/websitedevelopment'
  },
];

function NavListMenu2() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = navListMenuItems2.map(
    ({ link, title, description }, key) => (
      
        <Link to={link}>
          <MenuItem className="flex items-center gap-6 rounded-lg px-4  hover:shadow-md hover:rounded-md transition duration-300">
            <div>
              <Typography
                variant="h6"
                color="blue-gray"
                className="flex items-center text-sm font-bold"
              >
                {title}

              </Typography>
              <Typography
                variant="paragraph"
                className="text-xs !font-medium text-blue-gray-500"
              >
                {description}
              </Typography>
            </div>
          </MenuItem>
        </Link>
    ),
  );
 
  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Solutions
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}
 
function NavList() {
  return (
    <div >
      <List className="mt-4 mb-6 p-4 lg:mt-0 lg:mb-0 lg:flex-row lg:p-2 rounded-md ">

      <Typography as="a" href="#" variant="small" color="blue-gray" className="font-medium">
        <Link to="/home">
          <ListItem className="flex items-center gap-2 py-2 pr-4">Home</ListItem>
        </Link>
      </Typography>

      <NavListMenu />
      <NavListMenu2 />

      <Typography as="a" href="#" variant="small" color="blue-gray" className="font-medium">
        <Link to="/about">
          <ListItem className="flex items-center gap-2 py-2 pr-4">About</ListItem>
        </Link>
      </Typography>

      <Typography as="a" href="#" variant="small" color="blue-gray" className="font-medium">
        <Link to="/contact">
          <ListItem className="flex items-center gap-2 py-2 pr-4">Contact Us</ListItem>
        </Link>
      </Typography>

      </List>
    </div>
    
  );
}
 
function Navbar2() {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
 
  return (
    <div className="w-full fixed z-40 bg-none">
      <Navbar className="mx-auto w-full px-4 py-0 bg-white border-none">
        <div className="flex items-center justify-between text-blue-gray-900 ">
          <Link id='link_to_home' to="/home">
            <img className="h-16 w-auto" src={logo} alt="Your Company"></img>
          </Link>
          <div className="hidden lg:block">
            <NavList />
          </div>
          
          <IconButton
            variant="text"
            color="blue-gray"
            className="lg:hidden"
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>
        <Collapse open={openNav}>
          <NavList />
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Navbar2;