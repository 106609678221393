import React from 'react';
import cisco from '../Assets/trustedpartners/cisco.png'
import aa from '../Assets/trustedpartners/aa.jpg'
import aws from '../Assets/trustedpartners/aws.png'
import huawei from '../Assets/trustedpartners/huawei.png'
import microsoft from '../Assets/trustedpartners/microsoft.png'
import ifs from '../Assets/trustedpartners/ifs.png'
import tenable from '../Assets/trustedpartners/tenable.png'
import veeam from '../Assets/trustedpartners/veeam.png'
import vmware from '../Assets/trustedpartners/vmware.jpg'

function About() {
    return(

        <div id='about_container'>

            <div id='about_header'>
                <div>
                    <h1 id='about_header_h1'>About Us</h1>
                    <h1 id='about_header_h1' className='h1_2'>Who are we?</h1>
                    <p id='about_header_p'>
                    Aariva is a Level 1 BBBEE Company headquartered in Johannesburg South Africa and having offshore precense in 
                    Pune, India. We also have operational presence in all the major cities in South Africa - 
                    Johannesburg, Cape Town, Durban, Bloemfontein, Port Elizabeth and East London. We implement and 
                    support technologies using best practice solution services, all backed by a collective of professional teams, 
                    who bring a wealth of expertise to our product offerings. To keep you at the forefront of digital transformation 
                    and keep you focussed on your core business… you need someone that can handle your end-to-end digital transformation 
                    business requirements, in this ever-changing world, with Exelligent - the future is certain.
                    </p>
                </div>
                <div id='header_col2'>
                    <h1 id='header_col2_h1'>Local Company with global reach...</h1>
                    <p id='header_col2_p'>South African Company started in 2019</p>
                    <p id='header_col2_p'>BBBEE Level 1 </p>
                    <p id='header_col2_p'>Black Shareholding at 100% </p>
                    <p id='header_col2_p'>We hold a 100% shareholding of Aariva Pvt Limited in India</p>
                    <p id='header_col2_p'>Operation presence in major cities in South Africa</p>
                </div>
            </div>

            <div id='about_row2'>
                <div id='header_col2'>
                    <h1 id='header_col2_h1'>Everyone wants to stand out, but this makes us different...</h1>
                    <p id='header_col2_p'>Customer Centric</p>
                    <p id='header_col2_p'>Industry Focussed </p>
                    <p id='header_col2_p'>Product Agnostic </p>
                    <p id='header_col2_p'>Time to Value</p>
                </div>
                <div id='about_details'>
                    <h1 id='about_details_h1' className='h1_2'>What we do?</h1>
                    <p id='about_details_p'>
                    Aariva specialises in products and services across major IT verticals including Application 
                    Management and Support, Application Development, Infrastructure Implementation, Infrastructure Management and Support and Automation.
                    </p>
                    <p id='about_details_p'>
                    Our solution constructs include Unified Communications and Collaboration, Security, Internet of Things, Big Data Analytics, 
                    IT Managed Services coupled with next level automation for the insurance, banking and manufacturing industries.
                    </p>
                </div>
            </div>

            <div id='why_details'>
                <h1 id='why_details_h1' className='h1_2'>Why choose Aariva?</h1>
                <p id='why_p'>•  Realtime, personal service delivery</p>
                <p id='why_p'>•  Industry specific expertise</p>
                <p id='why_p'>•  Scalability - Scale up and out from a range of resources locally and internationally</p>
                <p id='why_p'>•  Growing geographic reach</p>
                <p id='why_p'>•  Experience - Years of experience in the IT industry, exposure to global multinational projects, small- and large-scale projects under the belt.</p>
                <p id='why_p'>•  ITIL/PMP/DevOps/Agile Aligned Services - whether its delivering services or implementing projects, they are all delivered using industry standard frameworks</p>
                <p id='why_p'>•  Driven to Drive Down Costs - we know you're on a journey, we want to be part of that journey and we'll make sure you're going to save money at the same time (make sure you ask us about this)</p>
                <p id='why_p'>•  Flexibility - we can work with small or large customers and when pricing matters the most, we can work with customized pricing models to suite your needs</p>
            </div>


            <div id='trustedpartners'>
                <h1 id='trustedpartners_h1'>Trusted Partners</h1>
                <div id='trustedpartners_images'>
                    <div>
                    <img id='trustedpartnerimg' src={aa} alt='trusted partner'></img>
                    </div>
                    
                    <div>
                    <img id='trustedpartnerimg' src={aws} alt='trusted partner'></img>
                    </div>
                    
                    <div>
                    <img id='trustedpartnerimg' src={cisco} alt='trusted partner'></img>
                    </div>
                    
                    <div>
                    <img id='trustedpartnerimg' src={huawei} alt='trusted partner'></img>
                    </div>
                    
                    <div>
                    <img id='trustedpartnerimg' src={microsoft} alt='trusted partner'></img>
                    </div>
                    
                    <div>
                    <img id='trustedpartnerimg' src={ifs} alt='trusted partner'></img>
                    </div>
                    
                    <div>
                    <img id='trustedpartnerimg' src={tenable} alt='trusted partner'></img>
                    </div>
                
                    <div>
                    <img id='trustedpartnerimg' src={veeam} alt='trusted partner'></img>
                    </div>
                    
                    <div>
                    <img id='trustedpartnerimg' src={vmware} alt='trusted partner' className=''></img>
                    </div>
                </div>

            </div>



        </div>
        
    );
}

export default About;