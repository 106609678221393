import React from 'react';


function RPAforindustries() {
  return(
      <div id='RPAindustries_container'>


        <div id='RPAbanking_content'>
            <h1 id='RPAbanking_h1'>RPA in Banking and Finance Industry</h1>
            <p id='RPAbanking_p'>
            In this digital age, banking and financial institutions are one of the first to adapt to new technology. 
            The digital systems are already making a difference in frontend and back end services. With AI and Machine Learning, 
            RPA solutions can put thinking into work, making them more helpful. Banking institutions have huge potential use 
            for automation due to the large number of manual and mundane processes.
            </p>
            <p id='RPAbanking_p'>
            Our Intelligent RPA Solutions has a differentiating factor that can service both IT and business process at the same time - 
            freeing up talent from repetitive work. It provides operational efficiency and reduces costs with increased compliance. 
            Our RPA solutions reduces implementation cost with its simple and faster implementation process through our unique Ready Action Bots.
            </p>

            <div id='RPAbanking_challenges'>
                <h1 id='RPAbanking_challenges_h1'>Challenges for Banks and Financial Institutions</h1>
                <div id='RPAbanking_challenges_cards'>
                    <div id='RPAbanking_challenges_card_item'>
                        <h1 id='RPAbanking_challenges_card_h1'>Huge number of manual processes</h1>
                        <p id='RPAbanking_challenges_card_p'>Banking industry is largely dependent on manual processes. Such a large number of manual processes slow down the process, 
                            increasing execution time and inefficiencies.
                        </p>
                    </div>
                    
                    <div id='RPAbanking_challenges_card_item'>
                        <h1 id='RPAbanking_challenges_card_h1'>Strict regulatory and compliance rules</h1>
                        <p id='RPAbanking_challenges_card_p'>Being the most impactful industry for any economy, banking is strictly regulated and has to be 
                            compliant to many rules. Failing which can lead to huge issue.
                        </p>
                    </div>
                    
                    <div id='RPAbanking_challenges_card_item'>
                        <h1 id='RPAbanking_challenges_card_h1'>Back office inefficiencies</h1>
                        <p id='RPAbanking_challenges_card_p'>Banking system has worked on paper based manual processes for a very long time which has held back its growth. 
                            Huge customer information is collected on paper forms which need to be entered in systems increasing manual work.
                        </p>
                    </div>

                    <div id='RPAbanking_challenges_card_item'>
                        <h1 id='RPAbanking_challenges_card_h1'>Multiple outdated legacy systems</h1>
                        <p id='RPAbanking_challenges_card_p'>The early adoption of technology in Banks and financial institutions has now left them with legacy systems 
                            which do not integrate with new systems. Our RPA can integrate systems irrespective of their compatibility 
                            enabling information accessible between systems.
                        </p>
                    </div>
                </div>
            </div>

            <div id='RPAbanking_impact'>
                <h1 id='RPAbanking_impact_h1'>RPA Impact on Banking & Financial Institutions</h1>
                <p id='RPAbanking_impact_p'>Claim Processing</p>
                <p id='RPAbanking_impact_p'>Bank Account Opening</p>
                <p id='RPAbanking_impact_p'>Credit Underwriting</p>
                <p id='RPAbanking_impact_p'>Anti-Money Laundering</p>
                <p id='RPAbanking_impact_p'>Compliance</p>
                <p id='RPAbanking_impact_p'>KYC Process</p>
            </div>
        </div>


        <div id='RPAinsurance'>
            <h1 id='RPAinsurance_h1'>RPA in Insurance Industry</h1>
            <p id='RPAinsurance_p'>Insurance companies are vigorously immersed with back-office forms as most of the documentation process is paper based. 
                Insurers regularly handle an exceptionally high volume of business processes like claims-processing and underwriting as 
                well as providing policy quotes manually which creates significant delay in time and affects the customer satisfaction, 
                business conversion and profitability.
            </p>
            <p id='RPAinsurance_p'>
                Our Intelligent RPA Solutions with AI capability can analyse large volumes of data and deal with exceptions and translate them into 
                insights to take actions. To go beyond just cost reduction and efficiency by adding significant value to businesses with a 
                personalized and higher degree of self-service.
            </p>

            <div id='RPAinsurance_processes_cards'>
                <div id='RPAinsurance_card_item'>
                    <h1 id='RPAinsurance_card_h1'>Entry Process</h1>
                    <p id='RPAinsurance_card_p'>Insurance quote generation</p>
                    <p id='RPAinsurance_card_p'>Proposal generation</p>
                    <p id='RPAinsurance_card_p'>Policy generation</p>
                    <p id='RPAinsurance_card_p'>Renewals</p>
                    <p id='RPAinsurance_card_p'>Underwriting</p>
                </div>

                <div id='RPAinsurance_card_item'>
                    <h1 id='RPAinsurance_card_h1'>Servicing Process</h1>
                    <p id='RPAinsurance_card_p'>Endorsement</p>
                    <p id='RPAinsurance_card_p'>Reporting</p>
                    <p id='RPAinsurance_card_p'>Claims for Insurance</p>
                    <p id='RPAinsurance_card_p'>Reimbursement/Cashless Mediclaim</p>
                    <p id='RPAinsurance_card_p'>No Claim Bonus(NCB) transfer (B to B)</p>
                    <p id='RPAinsurance_card_p'>Payment/Premium Suspense</p>
                </div>

                <div id='RPAinsurance_card_item'>
                    <h1 id='RPAinsurance_card_h1'>Exit Process</h1>
                    <p id='RPAinsurance_card_p'>Surrender</p>
                    <p id='RPAinsurance_card_p'>Death Claim for Life Insurance</p>
                    <p id='RPAinsurance_card_p'>Maturity Claim for Life Insurance - Money Back</p>
                    <p id='RPAinsurance_card_p'>Claims Payout</p>
                </div>
            </div>
        </div>
        

        <div id='RPAtelecom'>
            <h1 id='RPAtelecom_h1'>RPA in Telecommunication</h1>
            <p id='RPAtelecom_p'>
            Telecommunications is a fast growing, leading edge industry which helps us stay connected. With this fast growth, come challenges. 
            Rapid growth increases the burden on operational processes like managing data, increasing cost, quality of support and many more. 
            These challenges prevent telecom companies from providing better customer service.
            </p>
            <p id='RPAtelecom_p'>
            With the inclusion of Robotic Process Automation, the telecom industry can increase the operational efficiency and speed of 
            operations thus reducing the cost pressures. Automating processes will streamline process and increase customer response time with accuracy. 
            The commonly automated processes in the telecom industry are service fulfilment, service assurance, billing, 
            revenue management and network management etc.
            </p>
            <p id='RPAtelecom_p'>
            After implementing RPA, this vertical can leverage improved service delivery, reduced cost, decreased human intervention and high level of scalability.
            </p>
        </div>
        
        <div id='RPAtelecom'>
            <h1 id='RPAtelecom_h1'>RPA in Shipping and Logistics Industry</h1>
            <p id='RPAtelecom_p'>
            The transportation and logistics industry are rapidly going through digital transformation. Analysis by the World Economic Forum and 
            Accenture states that there is $1.5 trillion of value at stake for logistics players, and a further $2.4 trillion worth of 
            societal benefits, as a result of widespread digital transformation in the transportation industry.
            </p>
            <p id='RPAtelecom_p'>
            Shipping and logistics companies are highly under pressure on budgets by customers and shipping firms demanding higher 
            prices for their services. In the shipping and logistics industry, back office operations like updating customer details 
            on portals with the current status and ETA, scheduling a delivery, or tracking are performed manually.
            </p>
            <p id='RPAtelecom_p'>
            Aariva's Intelligent Robotic Process Automation can automate such mundane tasks and can work round the clock, 
            increasing operational efficiency, productivity and reducing associated costs. By automating the core business processes, 
            the task will be less tedious and will meet SLAs and improve customer expectations.
            </p>
        </div>

        <div id='RPAtelecom'>
            <h1 id='RPAtelecom_h1'>RPA in Manufacturing Industry</h1>
            <p id='RPAtelecom_p'>
            Manufacturing industry was focused on optimizing the operational processes like assembling, testing and packaging
             products using physical robots. But the industry still struggles to keep back office processes like invoice processing, 
             procurement, vendor communication and report generation in check.
            </p>
            <p id='RPAtelecom_p'>
            In order to remain competitive, manufacturing industry must focus on optimizing these processes using robotic
             process automation to reduce costs while improving efficiency.
            </p>
            <p id='RPAtelecom_p'>
            When there is a significant volume of work that must be processed frequently, RPA is most effective solution to 
            optimize these repetitive back offices like quoting, invoicing, accounts payable, accounts receivable, 
            general ledger operations, sales pipeline and Pricing Report Creation.
            </p>
        </div>

      </div>
  );
};

export default RPAforindustries;